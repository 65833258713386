.header {
    display: flex;
    justify-content: space-between;
    background-color: $primary;
    color: white;
    height: 65px;
    align-items: center;
    padding-left: 3vw;
    padding-right: 3vw;
    box-shadow: 0px 1px 5px 0px rgba(87, 86, 87, 1);

    .logo {
        margin-right: 30px;
    }
}

.logo-section {
    display: flex;
    align-items: center;
}

.logo {
    align-self: center;
    height: 32px;
    max-width: 50%;
}

.dashboard-title {
    font-size: 25px;
}

.dropdown {
    display: flex;
    align-items: center;
}

.restricted-height-toggle {
    height: 28px;
}

.toggle {
    display: flex;
    align-items: center;
    border: none;
    width: 20vw;
    padding: 0;
    margin-left: 7px;

    &:hover, &:focus {
        background-color: transparent;
        border: none;
        outline: none;
    }
}

// Various bootstrap overrides for adjusting the styles
.dropdown-toggle {
    background-color: transparent;
    color: black;
    padding: 0;

    &:hover {
        color: $primary;
        font-weight: 700;
    }

    &:focus {
        background-color: transparent;
        box-shadow: none !important;
        color: black;
    }

    &:active {
        background-color: transparent;
    }

    &:after {
        display: none !important;
    }
}

.dropdown-item {
    display: block !important;
    white-space: break-spaces !important;

    &:hover {
        color: #3c54a3;
        font-weight: 700;
    }
}

.btn-check:active+.btn-success, .btn-check:checked+.btn-success, .btn-success.active, .btn-success:active, .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: black;
    background-color: transparent;
}

.user-dropdown {
    height: 30px;
    margin-right: 15px;
    padding-bottom: 12px;

    .user-dropdown-menu {
        width: 22vw;

        .team-toggle {
            width: 95%;
            white-space: break-spaces !important;
            text-align: left;
        }

        .team-dropdown {
            width: 22vw;
            max-height: 300px;
            overflow-y: auto;
        }
    }

    .dropdown-menu-toggle {
        width: 95%;

        &:hover {
            color: $primary!important;
        }

        &:active, &:focus {
            background-color: $primary!important;
            color: white !important;
        }
    }

    .dropdown-item {
        display: block !important;
        white-space: break-spaces !important;

        &:hover {
            color: $primary!important;
        }

        &:active, &:focus {
            background-color: $primary!important;
            color: white !important;
        }
    }

    .no-hover {

        &:hover {
            color: black!important;
            background-image: none;
            background-color: white;
            font-weight: normal;
            cursor:default;
        }

        &:active, &:focus {
            background-color: transparent!important;
            color: black !important;
        }
    }

    .user-button {
        display: flex;
        align-items: center;
        height: 50px;
        border: none;
        padding: 0;
        margin-left: 7px;
        margin-right: 10px;
        margin-top: 10px;

        .user-info {
            display: flex;
            flex-direction: column;
            color: white;
        }

        .avatar {
            float: right;
            height: 45px;
            border-radius: 50px;
            color: white;
            margin-left: 10px;

            &:hover {
                text-decoration: underline;
                font-weight: normal;
            }
        }

        &:hover, &:focus {
            background-color: transparent;
            border: none;
            outline: none;
            text-decoration: underline;
            color: white;
            text-decoration: underline;
            text-decoration-color: white !important;
            font-weight: normal;
        }
    }
}

.rotate {
    transform: rotate(90deg);
}
